<script setup>
const props = defineProps({
  file: {
    type: String,
    required: true
  },
  screens: {
    type: Object,
    default: () => {
      return {
        mob: { size: 'min' },
        tab: { size: 'min' },
        desktop: { size: 'min' },
        iwb: { size: 'org' }
      };
    }
  },
  img: {
    type: Object,
    default: () => ({})
  },
  pcImage: {
    type: Boolean,
    default: false
  },
  //if mode is
  //device: device based (mobile, tablet, desktop)
  //resolution: resolution based (screen width: 576px, 768px, 992px)
  //static: same in all resolutions
  mode: {
    type: String,
    default: 'device',
    enum: ['device', 'resolution', 'static']
  }
});

const { file, screens, img, pcImage, mode } = props;

const device = useDevice();
const viewport = useViewport();

let responsiveFileName;
let imageBasePath;
const imageURL = ref();
let imageAttribs;

const modeHandlers = {
  /**
   * returns same image name for all resolutions and all devices
   */
  static: ({ file, img }) => {
    return [file, img];
  },
  /**
   * This functions runs when device specific strategy is selected
   * takes a breakpoint
   * returns the image responsive file name
   */
  device: ({ device, newBreakpoint, nameSplit }) => {
    if (device.isMobile) {
      return [
        `${nameSplit[0]}-${screens.mob.size}.${nameSplit[1]}`,
        screens.mob.img
      ];
    } else if (device.isTablet) {
      return [
        `${nameSplit[0]}-${screens.tab.size}.${nameSplit[1]}`,
        screens.tab.img
      ];
    } else if (newBreakpoint == 'desktopWide') {
      return [`${nameSplit[0]}.${nameSplit[1]}`, screens.desktop.img];
    } else {
      return [
        `${nameSplit[0]}-${screens.desktop.size}.${nameSplit[1]}`,
        screens.desktop.img
      ];
    }
  },
  /**
   * This functions runs when resolution specific strategy is selected
   * takes a breakpoint
   * returns the image responsive file name
   */
  resolution: ({ newBreakpoint, nameSplit }) => {
    switch (newBreakpoint) {
      case 'mobile':
      case 'mobileWide':
      case 'mobileMedium':
        return [
          `${nameSplit[0]}-${screens.mob.size}.${nameSplit[1]}`,
          screens.mob.img
        ];
      case 'tablet':
      case 'tabletWide':
      case 'tabletMedium':
        return [
          `${nameSplit[0]}-${screens.tab.size}.${nameSplit[1]}`,
          screens.tab.img
        ];
      case 'desktopWide':
        return [`${nameSplit[0]}.${nameSplit[1]}`, screens.desktop.img];
      default:
        return [
          `${nameSplit[0]}-${screens.desktop.size}.${nameSplit[1]}`,
          screens.desktop.img
        ];
    }
  }
};

/**
 * takes a file name with its extension
 * returns filename and extension separately
 * @param {*} filename
 * @returns
 */
function splitFilename(filename) {
  const lastIndex = filename.lastIndexOf('.');
  const name = filename.slice(0, lastIndex);
  const ext = filename.slice(lastIndex + 1);

  return [name, ext];
}

watch(
  viewport.breakpoint,
  (newBreakpoint, oldBreakpoint) => {
    const runtimeConfig = useRuntimeConfig();
    const isDevEnv = runtimeConfig.public.appEnv == 'local';
    const nameSplit = splitFilename(file);
    if (isDevEnv) {
      imageBasePath = pcImage ? '' : '/_nuxt/';
      responsiveFileName = pcImage
        ? `/pc/img/${file}`
        : `assets/img/${nameSplit[0]}.${nameSplit[1]}`;
    } else if (nameSplit[1] == 'svg') {
      responsiveFileName = file;
    } else {
      [responsiveFileName, imageAttribs] = modeHandlers[mode]({
        file,
        img,
        device,
        newBreakpoint,
        nameSplit
      });
    }

    Object.assign(img, imageAttribs);

    if (!isDevEnv) {
      imageBasePath = pcImage
        ? runtimeConfig.public.cdnPCAssets + 'pc/img/'
        : runtimeConfig.public.cdnURL + '/_nuxt/';
    }

    imageURL.value = imageBasePath + responsiveFileName;
  },
  {
    immediate: true
  }
);
</script>

<template>
  <img
    v-bind="img"
    :fetchpriority="file == 'homepage-hero.webp' ? 'high' : 'auto'"
    :src="imageURL"
    :alt="img.alt"
  />
</template>
